<template>
    <div>
        <Navbar mode="solid" class="navbar" :show-btn-icon="true" :show-avatar="false" :show-btns="false"
            :btn-link-path="backPath" />
        <main class="main">
            <div class="wrapper">
                <h1 class="page-title" v-t="'page.group_tour.traveller_details.title'" />
                <p class="page-description" v-t="'page.group_tour.traveller_details.title'" />
                <form action="" class="form" @submit.prevent="submit">
                    <div class="form-page">
                        <Button :text="$t('common.cta.add_a_traveller')" class="w-full" kind="secondary"
                            icon-class="bx bx-plus" type="button" @click.native="createNewTraveller()" />
                        <article class="traveller--item" v-for="(traveller, index) of travellers" :key="index">
                            <div>
                                <div class="traveller--item--header">
                                    <div class="traveller--item--user--container">
                                        <h3 class="traveller--item--name">
                                            {{ traveller.firstName }}
                                            {{ traveller.lastName }}
                                        </h3>
                                        <p class="traveller--item--detail" v-if="traveller.lead">
                                            {{ traveller.email }}
                                        </p>
                                    </div>
                                    <span class="traveller--item--traveller--type--tag" v-if="traveller.lead"
                                        v-t="'common.lead_traveller'" />
                                </div>
                                <div class="tags--container">
                                    <p class="traveller--item--detail--tag" v-if="traveller.lead">
                                        <i class="bx bxs-phone"></i>
                                        {{ traveller.phoneNumber }}
                                    </p>
                                    <p class="traveller--item--detail--tag">
                                        <i class="bx bxs-cake"></i>
                                        {{ traveller.birthDate | date("date") }}
                                    </p>
                                    <p class="traveller--item--detail--tag">
                                        <i class="bx bx-world"></i>
                                        {{ getNationalityByLang(traveller.nationality) }}
                                    </p>
                                </div>
                            </div>
                            <div class="traveller--item--btn--container">
                                <Button size="xs" :text="$t('common.cta.edit')" icon-class="bx bxs-edit" class="w-full"
                                    type="button" @click.native="
                                                                                editTraveller(traveller, index)
                                                                            " />
                                <Button v-if="!traveller.lead" size="xs" :text="$t('common.cta.remove')" class="w-full"
                                    icon-class="bx bxs-trash" type="button" @click.native="
                                                                                removeTraveller(traveller.id)
                                                                            " />
                            </div>
                        </article>
                        <Button :text="$t('trip_request.button')" class="desktop-cta" size="md" kind="primary" type="button"
                            @click.native="goToNextPage()" />
                    </div>
                </form>
            </div>
        </main>
        <div class="bottom-nav">
            <div class="bottom-nav-wrapper">
                <Button kind="primary" size="sm" :text="`${$t('trip_request.button')}`" class="block h-12 px-8 w-full"
                    @click.native="goToNextPage()" />
            </div>
        </div>
        <div class="traveller--details" v-show="showTravellerForm">
            <Button icon-class="bx bx-x" shape="round" :show-text="false" type="button" class="btn-close"
                @click.native="closeTravellerForm()" />
            <h2 class="sub--title" v-if="isLead" v-t="'page.group_tour.traveller_details.form_lead.title'" />
            <h2 class="sub--title mb-8" v-else v-t="'page.group_tour.traveller_details.form.title'" />
            <p class="mb-4 text-black-light" v-if="isLead" v-t="'page.group_tour.traveller_details.form_lead.sub_title'" />
            <div class="form-input--container">
                <InputText name="first-name" :label="$t('common.first_name')" :placeholder="$t('common.first_name')"
                    v-model="traveller.firstName" :error-msg="firstNameErrMsg"
                    :show-err-msg="$v.traveller.firstName.$error" />
                <InputText name="last-name" :label="$t('common.last_name')" :placeholder="$t('common.last_name')"
                    v-model="traveller.lastName" :error-msg="lastNameErrMsg" :show-err-msg="$v.traveller.lastName.$error" />
                <InputText v-if="isLead" name="email" :label="$t('common.email')" :placeholder="$t('common.email')"
                    v-model="traveller.email" :error-msg="emailErrMsg" :show-err-msg="$v.traveller.email.$error" />
                <InputPhone v-model="traveller.phoneNumber" v-if="isLead" :error-msg="phoneNumberErrMsg"
                    :show-err-msg="$v.traveller.phoneNumber.$error" />
                <DatePicker 
                    v-if="showDate" 
                    v-model="traveller.birthDate" 
                    :error-msg="birthDateErrMsg"
                    :show-err-msg="$v.traveller.birthDate.$error" 
                    :label="$t('common.birthdate')"
                    :placeholder="$t('common.birthdate')" 
                    class="w-full"
                    @getDate="setDate" 
                    ref="datePicker"
                    v-bind="$attrs" />
                <SelectDropdown v-model="traveller.nationality" :show-description="true"
                    :description="$t('common.select_nationality')" :options-list="countriesOptions"
                    :label="$t('common.nationality')" :show-label="true" :error-msg="nationalityErrMsg"
                    :show-err-msg="$v.traveller.nationality.$error" />
            </div>
            <Button v-if="isUpdate" kind="primary" :text="$t('common.cta.update_traveller_details')" class="w-full"
                @click.native="updateTraveller()" />
            <Button v-if="isCreate" kind="primary" :text="$t('common.cta.add_traveller')" class="w-full" size="lg"
                @click.native="setTraveller()" />
        </div>
        <BackgroundLayer v-if="showBackground" @click.native="closeTravellerForm()" />
</div>
</template>

<script>
import Navbar from "@/components/layout/Navbar.vue";
import Button from "@/components/buttons/Button.vue";
import InputText from "@/components/inputs/InputText.vue";
import { InputPhone } from "@/components";
import SelectDropdown from "@/components/dropdowns/SelectDropdown.vue";
import BackgroundLayer from "@/components/BackgroundLayer.vue";
import countries from "@/data/world/countries.json";
import DatePicker from "@/components/date-pickers/DatePicker.vue";
import { mapActions, mapGetters } from "vuex";
const { required, email, requiredIf } = require("vuelidate/lib/validators");

export default {
    name: "TravellerDetails",
    components: {
        Navbar,
        Button,
        InputText,
        InputPhone,
        SelectDropdown,
        BackgroundLayer,
        DatePicker,
    },
    data() {
        return {
            countries,
            loading: false,
            isUpdate: false,
            isCreate: false,
            isLead: false,
            showDate: true,
            showBackground: false,
            showTravellerForm: false,
            countriesOptions: [],
            currentIndex: undefined,
            traveller: {
                id: "",
                firstName: "",
                lastName: "",
                email: "",
                birthDate: "",
                phoneNumber: "",
                nationality: "",
                lead: false,
            },
            travellers: [],
        };
    },
    validations: {
        traveller: {
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            email: {
                required: requiredIf(function () {
                    return this.traveller.lead === true;
                }),
                email,
            },
            birthDate: {
                required,
            },
            phoneNumber: {
                required: requiredIf(function () {
                    return this.traveller.lead === true;
                }),
            },
            nationality: {
                required,
            },
        },
    },
    methods: {
        ...mapActions({
            setTravellers: "tour/setTravellers",
            setCurrentRoute: "tour/setCurrentRoute",
        }),
        goToNextPage() {
            if (this.travellers.length > 0) {
                this.$router.push(
                    `/book/accommodation?id=${this.$route.query.id}&date_id=${this.$route.query.date_id}&trip_date=${this.$route.query.trip_date}&origin=${this.$route.query.origin}`
                );
            } else {
                this.$notify({
                    type: "error",
                    text: this.$t("common.error.add_at_least_one_traveller"),
                });
            }
        },
        setDate(date) {
            this.traveller.birthDate = date.isoString;
        },
        removeTraveller(travellerId) {
            this.travellers = this.travellers.filter(
                (traveller) => traveller.id != travellerId
            );

            //Set to store
            this.setTravellers(this.travellers);
        },
        setTraveller() {
            this.$v.traveller.$touch();
            if (!this.$v.traveller.$invalid) {
                let traveller = {};

                if (this.isLead === true) {
                    traveller.lead = true;
                    traveller.email = this.traveller.email;
                    traveller.phoneNumber = this.traveller.phoneNumber;
                }

                if (this.isLead === false) {
                    traveller.lead = false;
                    traveller.email = "";
                    traveller.phoneNumber = "";
                }

                traveller.id = this.traveller.id;
                traveller.firstName = this.traveller.firstName;
                traveller.lastName = this.traveller.lastName;
                traveller.nationality = this.traveller.nationality;
                traveller.birthDate = this.traveller.birthDate;

                this.travellers.push(traveller);
                this.setTravellers(this.travellers);
                this.closeTravellerForm();
            }
        },
        getNationalityByLang(nationality) {
            let nationalityByLang = this.countriesOptions.filter(
                (option) => option.value === nationality
            );
            return nationalityByLang[0].name;
        },
        updateTraveller() {
            this.$v.traveller.$touch();
            if (!this.$v.traveller.$invalid) {
                this.travellers[this.currentIndex] = this.traveller;
                this.setTravellers(this.travellers);
                this.closeTravellerForm();
            }
        },
        resetTravellerObject() {
            this.traveller.id =
                this.traveller.phoneNumber =
                this.traveller.birthDate =
                this.traveller.email =
                this.traveller.firstName =
                this.traveller.lastName =
                this.traveller.nationality =
                    "";
            this.traveller.lead = false;
        },
        editTraveller(currentTraveller, index) {
            this.currentIndex = index;
            this.traveller.id = currentTraveller.id;
            this.isCreate = false;
            this.isUpdate = true;
            this.traveller.lead = currentTraveller.lead;
            this.traveller.firstName = currentTraveller.firstName;
            this.traveller.lastName = currentTraveller.lastName;
            this.traveller.email = currentTraveller.email;
            this.traveller.phoneNumber = currentTraveller.phoneNumber;
            this.traveller.nationality = currentTraveller.nationality;
            this.$refs[
                "datePicker"
            ].$el.children[0].children[1].children[1].value =
                currentTraveller.birthDate;

            currentTraveller.lead
                ? (this.isLead = true)
                : (this.isLead = false);
            this.openTravellerForm();
        },
        createNewTraveller() {
            this.resetTravellerObject();
            this.traveller.id = this.createId();
            this.travellers.length < 1
                ? (this.isLead = true)
                : (this.isLead = false);
            this.isLead
                ? (this.traveller.lead = true)
                : (this.traveller.lead = false);
            this.isUpdate = false;
            this.isCreate = true;
            this.openTravellerForm();
        },
        openTravellerForm() {
            let dateId = Number(this.$route.query.date_id);
            let date = this.tour.dates.filter((date) => date.id === dateId);
            if (this.travellers.length < date[0].places_left) {
                this.$v.traveller.$reset();
                this.showBackground = this.showTravellerForm = true;
            } else {
                this.closeTravellerForm();
                this.$notify({
                    type: "error",
                    text: this.$t(
                        "common.error.group_tour_max_travellers_reached"
                    ),
                });
            }
        },
        createId() {
            if (this.travellers.length === 0) {
                return 1;
            }

            if (this.travellers.length >= 1) {
                let latestTraveller =
                    this.travellers[this.travellers.length - 1];
                return latestTraveller.id + 1;
            }
        },
        closeTravellerForm() {
            this.showBackground = this.showTravellerForm = false;
        },
    },
    computed: {
        ...mapGetters('auth', ['isAuthenticated']),
        ...mapGetters({
            travellersArray: "tour/travellers",
            tour: "tour/tour",
        }),
        backPath() {
            if (this.isAuthenticated === true) { 
            if (this.$route.query.origin === "dates")
                return `/book/dates?id=${this.$route.query.id}&origin=dates`;
            else
                return `/tour?id=${this.$route.query.id}`;
            } else {
                let query = this.$route.query;
                let origin = query["origin"];
                let dateId = query["date_id"];
                let tripDate = query["trip_date"];
                let id = query["id"];
                return `/book/login?id=${id}&date_id=${dateId}&trip_date=${tripDate}&origin=${origin}`;
            }
        },
        firstNameErrMsg() {
            return this.$t("common.error.first_name_required");
        },
        lastNameErrMsg() {
            return this.$t("common.error.last_name_required");
        },
        phoneNumberErrMsg() {
            return this.$t("common.error.phone_number_required");
        },
        emailErrMsg() {
            let msg;
            if (!this.$v.traveller.email.required) {
                msg = this.$t("common.error.email_required");
            }
            if (!this.$v.traveller.email.email) {
                msg = this.$t("common.error.email_valid");
            }
            return msg;
        },
        birthDateErrMsg() {
            return this.$t("common.error.birthdate_required");
        },
        nationalityErrMsg() {
            return this.$t("common.error.nationality_required");
        },
    },
    mounted() {
        let lang = localStorage.getItem("lang");
        this.countries.forEach((country) => {
            this.countriesOptions.push({
                value: country["en"],
                name: country[lang],
                selected: false,
                disabled: false,
            });
        });
        this.travellers = this.travellersArray;
        this.setCurrentRoute(this.$route.path);
    },
};
</script>

<style scoped>
.main {
    @apply pt-16 pb-32 px-4;
}

.navbar {
    @apply border-none;
}
.wrapper {
    @apply relative transform -translate-x-1/2 max-w-screen-sm left-1/2;
}

.bottom-nav {
    @apply fixed bottom-0 left-0 w-full p-4 bg-white border-t border-grey-base sm:mt-16;
    @apply block sm:hidden;
}

.bottom-nav-wrapper {
    @apply relative flex items-center justify-between max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.desktop-cta {
    @apply hidden sm:block h-12 px-8 w-full mt-8;
}

.page-title {
    @apply font-bold text-3xl text-black-base text-center;
}

.page-description {
    @apply text-black-lighter text-lg mb-12 text-center;
}

.traveller--details {
    @apply fixed left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2;
    @apply z-50 h-full md:h-auto overflow-hidden md:rounded-xl;
    @apply bg-white md:max-w-screen-sm w-full;
    @apply border border-grey-base py-12 px-4 sm:px-16;
    @apply overflow-y-auto;
}

@media (min-width: 1025px) {
    .traveller--details {
        max-height: calc(100vh - 32px);
    }
}

.sub--title {
    @apply font-semibold text-2xl text-black-base;
}

.traveller--item {
    @apply border border-grey-base shadow-sm px-3 py-3 rounded-xl;
    @apply mt-8;
}

.traveller--item--tag {
    @apply bg-violet-light rounded-md text-violet-text px-2 py-1 text-xxs font-medium mb-1 inline-block;
}

.traveller--item--name {
    @apply font-semibold text-black-base;
}

.traveller--item--detail {
    @apply text-sm text-black-lighter flex items-center gap-1 pb-0.5;
}

.tags--container {
    @apply flex flex-wrap gap-1 mt-1;
}
.traveller--item--detail--tag {
    @apply rounded-md px-2 py-1 bg-grey-base bg-opacity-50;
    @apply inline-flex items-center gap-1;
    @apply text-sm font-medium text-black-light;
}

.traveller--item--btn--container {
    @apply flex gap-3 items-center pt-3 w-full;
}

.traveller--item--btn--container--sm {
    @apply hidden sm:flex flex-col items-start gap-2;
}

.traveller--item--header {
    @apply flex items-start sm:items-center justify-between flex-wrap sm:flex-nowrap flex-col-reverse sm:flex-row;
}

.traveller--item--traveller--type--tag {
    @apply bg-violet-light bg-opacity-75 rounded-full text-violet-dark font-semibold;
    @apply text-xs uppercase px-2.5 py-0.5 inline-block;
}

.btn-close {
    @apply flex ml-auto;
}

.form-input--container {
    @apply grid gap-4 mb-6;
}
</style>