<template>
    <div class="date-picker-container">
        <label class="date-picker-field" for="date">
            <span class="date-picker-label">{{ label }}</span>
            <div class="date-picker-input-wrapper">
                <i class="bx bx-calendar"></i>
                <input 
                    id="date" 
                    :class="[showErrMsg ? 'border-red-base' : 'border-grey-dark', 'input']" 
                    :placeholder="placeholder" 
                    :value="date" 
                    class="date-picker-input" 
                    name="date" 
                    type="text"
                    v-bind="$attrs" 
                    @blur="$emit('blur')" />
            </div>
            <ErrorMessage v-if="showErrMsg" :errorMsg="errorMsg" class="mt-2" />
        </label>
    </div>
</template>
<script>
import "@/plugins/datedropper/datedropper-javascript";
import ErrorMessage from "@/components/ErrorMessage";
import dayjs from "dayjs";
const lang = localStorage.getItem("lang") || "en";
export default {
    name: "DatePicker",
    components: {
        ErrorMessage,
    },
    data() {
        return {
            date: "",
        };
    },
    props: {
        start: {
            type: String,
        },
        label: {
            type: String,
            default: "Label",
        },
        placeholder: {
            type: String,
            default: "Birthdate",
        },
        showErrMsg: {
            type: Boolean,
            default: false,
        },
        errorMsg: {
            type: String,
            default: "",
        },
    },
    computed: {
        getCurrentDate() {
            let week_days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            let months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ];
            let date = new Date();
            let week_day = week_days[date.getDay()];
            let month = months[date.getMonth()];
            let day = date.getDate();
            let year = date.getFullYear();

            return `${week_day}, ${day} ${month} ${year}`;
        },
    },
    methods: {
        dayjs(...args) {
            dayjs(...args);
        },
    },
    mounted() {
        this.date = this.start;
        new window.dateDropper({
            selector: ".date-picker-input",
            startFromMonday: true,
            format: "W, dd M y",
            minYear: 1900,
            showArrowsOnHover: false,
            expandedOnly: true,
            doubleView: false,
            overlay: true,
            range: false,
            lang: lang,
            onDropdownExit: (res) => {
                this.date = res.output.string;
                let unixTimeStamp = res.output.U * 1000;
                let isoString = new Date(unixTimeStamp).toISOString();
                this.$emit("getDate", {
                    date: this.date,
                    unixTimeStamp: unixTimeStamp,
                    isoString: isoString,
                });
            },
        });
    },
};
</script>
<style scoped>
@import "../../plugins/datedropper/datedropper.css";
.date-picker-container {
    @apply flex justify-between flex-wrap sm:flex-nowrap;
}
.date-picker-field {
    @apply w-full;
}

.date-picker-label {
    @apply block mb-2 font-semibold text-base text-black-base;
}

.date-picker-input-wrapper {
    @apply relative;
}

.date-picker-input-wrapper i {
    @apply absolute left-3 top-1/2 transform -translate-y-1/2;
}

.date-picker-input {
    @apply w-full border rounded-lg;
    @apply focus:border-teal-base focus:ring-teal-lighter focus:ring-2;
    @apply pl-10 pr-3 py-2.5;
}
</style>
